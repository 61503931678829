import React from 'react';
import './Waves.css';


const WaveDivider = () => {  
  return (
    <>
        <div className="wave-background" />
    </>
  );
}

export default WaveDivider;